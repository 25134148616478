export default {
  base: {
    yes: 'Yes',
    no: 'No',
    children: 'KIDS',
    and: 'and',
    dear: 'Dear',
    dear_boy: 'Dear',
    dear_girl: 'Dear',
    you: 'you',
    you_2: 'you'
  },
  intro: {
    text_1: 'Love never fails...',
    text_2: '1 Corinthians 13:8'
  },
  menu: {
    main: 'HOME',
    photos: 'PHOTOS',
    colors: 'WEDDING COLORS',
    messanger: 'Viber'
  },
  info: {
    dear: 'Dear',
    you: 'you',
    text_1: 'We invite you to share<br> in our joy and request<br> your presence at our wedding.<br> Our wedding will be on:',
    text_2: 'December 16, 2021',
    text_3: 'at 1:00 pm',
    text_4: 'We cannot imagine this joyful<br> day without you!',
    text_5: 'For your convenience, we have<br> created a website with detailed<br> information about our wedding.',
    text_6: 'We also want to share <br> a part of our love story. ',
    text_7: 'keep scrolling :)'
  },
  address: {
    text_1: 'The wedding ceremony<br> and banquet<br> will be at:',
    text_2: 'Morgan Creek Golf Club<br> 8791 Morgan Creek Ln,<br> Roseville, CA 95747',
    text_3: 'see on the map'
  },
  rsvp: {
    text_1: 'Ceremony',
    text_2: 'Banquet',
    text_3: 'Will you join us in celebrating?',
    text_4: 'If you will be with children, please<br> indicate the required number<br> of additional seats.',
    text_5: 'What parts of the event<br> are you planning to attend?',
    text_6: 'RSVP no later than december 4th, 2021'
  },
  our_story: {
    text_1: 'Our love story',
    text_2: 'Demyan',
    text_3: '“Every morning I wake up, I get so excited to know that I am marrying Ilona. Never have I loved someone as deeply as I love her. She is my treasure, my joy, and my life. There is no one else I would want to live out the rest of my life with other than her”',
    text_4: 'Ilona',
    text_5: 'He kindled a great feeling in my heart, I love him and I want to always be with him. Only with Demyan I am happy, only with him I feel completely free, and most loved!'
  },
  present: {
    text_1: 'With everything we have, we’ve been truly blessed,<br> Your presence and prayers are all we request;<br> But if you desire to give nonetheless,<br> A financial gift is one we suggest.',
    text_2: 'make a gift',
    text_3: 'Venmo @demyanlovesilona',
    text_4: 'Apple Pay (209)345-0120',
    text_5: 'Zelle (209)345-0120',
    text_6: 'Cashapp @demyanlovesilona',
    text_7: 'PrivatBank: 5167 9856 6120 7654'
  },
  countdown: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    ps: '... and we will be married'
  },
  getintouch: {
    text_1: 'If you want to take part<br> in the wedding program,<br> you can contact Demyan',
    text_2: 'i want to take part'
  },
  messanger: {
    text_1: 'For a quick exchange<br> of information, photos and videos<br> between our guests, we created<br> a group in Viber.',
    text_2: 'group chat'
  },
  no_invitation: {
    text_1: 'to open an invitation,<br> take advantage of your<br> unique link :)'
  },
  colors: {
    text_1: 'Need help getting dressed?<br> These are our wedding colors<br> that will look best!<br> And if your favorite color is blue,<br> That’s ok too!'
  },
  photos: {
    text_1: 'here will be our<br> wedding photos and videos'
  }
}
