<template lang="pug">
  .desktop-wrapper(ref="wrapper")
    slot(name="noscroll")
    vuescroll(
      v-if="useScroll"
      :ops="ops"
      @handle-scroll="onScroll")
      slot
    template(v-else)
      slot
</template>

<script>
const vuescroll = () => import('vuescroll')

const BREAKPOINT = 800

export default {
  name: 'DesktopWrapper',

  components: {
    vuescroll
  },

  data: () => ({
    ops: {
      vuescroll: {},
      scrollPanel: {
        scrollingX: false
      },
      rail: {},
      bar: {
        disable: true,
        opacity: 0
      }
    },
    width: 0
  }),

  computed: {
    useScroll () {
      return this.width >= BREAKPOINT
    }
  },

  mounted () {
    window.onresize = this.onResize
    this.onResize()
  },

  methods: {
    onResize () {
      const width = window.innerWidth
      if (this.width !== width) {
        this.width = width
        const vh = window.innerWidth < BREAKPOINT
          ? window.innerHeight * 0.01
          : window.innerHeight * 0.9 * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
      if (this.$refs.wrapper.offsetWidth < 400 && window.innerWidth > BREAKPOINT) {
        document.documentElement.style.setProperty('font-size', '50%')
      } else {
        document.documentElement.style.removeProperty('font-size')
      }
    },

    onScroll (vertical) {
      window.scrollTop = vertical.scrollTop
      window.dispatchEvent(new CustomEvent('scroll'))
    }
  }
}
</script>

<style lang="scss">
  .desktop-wrapper {
    box-sizing: content-box;
    position: relative;
    height: 90vh;
    width: calc(90vh * 0.48);
    top: 5vh;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 5rem;
    border: .5rem solid #111;
    box-shadow: 0 0 5rem rgba(0, 0, 0, .3);

    @media screen and (max-width: 800px) {
      height: auto;
      overflow: auto;
      width: 100%;
      top: 0;
      margin: 0;
      border-radius: 0;
      border: none;
      box-shadow: none;
    }
  }
</style>
