export default {
  base: {
    yes: 'Да',
    no: 'Нет',
    children: 'Дети',
    and: 'и',
    dear: 'Дорогие',
    dear_boy: 'Дорогой',
    dear_girl: 'Дорогая',
    you: 'тебя',
    you_2: 'вас'
  },
  intro: {
    text_1: 'Любовь<br> никогда не перестает...',
    text_2: '1-е Коринфянам 13:8'
  },
  menu: {
    main: 'главная',
    photos: 'фотографии',
    colors: 'палитра цветов',
    messanger: 'Viber'
  },
  info: {
    dear: 'Дорогие',
    you: 'вас',
    text_1: 'Приглашаем {you} разделить<br> нашу радость и просим<br> присутствовать на свадьбе.<br> Наша свадьба состоится:',
    text_2: '16 декабря 2021 года',
    text_3: 'начало в 13:00',
    text_4: 'И мы не можем представить этот<br> радостный день без вас!',
    text_5: 'Для вашего удобства мы создали<br> сайт с подробной информацией<br> о нашей свадьбе.',
    text_6: 'Мы также хотим поделиться<br> частичкой нашей истории любви.',
    text_7: 'листайте дальше :)'
  },
  address: {
    text_1: 'Свадебная церемония<br> и банкет состоятся<br> по адресу:',
    text_2: 'Morgan Creek Golf Club<br> 8791 Morgan Creek Ln,<br> Roseville, CA 95747',
    text_3: 'ПОСМОТРЕТЬ НА КАРТЕ'
  },
  rsvp: {
    text_1: 'ВЕНЧАНИЕ',
    text_2: 'БАНКЕТ',
    text_3: 'сможете ли Вы присоединиться<br> к празднованию вместе с нами?',
    text_4: 'ЕСЛИ БУДЕТЕ С ДЕТЬМИ, ПОЖАЛУЙСТА,<br> УКАЖИТЕ НУЖНОЕ КОЛИЧЕСТВО МЕСТ',
    text_5: 'НА КАКИХ ЧАСТЯХ МЕРОПРИЯТИЯ<br> ПЛАНИРУЕТЕ ПРИСУТСТВОВАТЬ?',
    text_6: 'Просьба сообщить о присутствии<br> до 4 декабря 2021 года'
  },
  our_story: {
    text_1: 'Наша история',
    text_2: 'ДЕМЬЯН',
    text_3: '"Каждое утро я просыпаюсь счастливым от того, что женюсь на Илоне. Никогда я не любил кого-то так сильно, как люблю ее. Она мое сокровище, моя радость и моя жизнь. Нет никого, с кем бы я хотел прожить остаток своей жизни, кроме нее"',
    text_4: 'ИЛОНА',
    text_5: 'Он зажег в моем сердце большое чувство, я люблю его и хочу всегда быть рядом. Только с Демьяном я счастлива, только с ним чувствую себя полностью свободной, счастливой и самой любимой!'
  },
  present: {
    text_1: 'Желали бы мы для нашего счастья,<br>>Искренних слов и в молитве участия,<br> А чтоб вам не думать что нам подарить,<br> Подарок ваш $кромный сюда положить.',
    text_2: 'сделать подарок',
    text_3: 'Venmo @demyanlovesilona',
    text_4: 'Apple Pay (209)345-0120',
    text_5: 'Zelle (209)345-0120',
    text_6: 'Cashapp @demyanlovesilona',
    text_7: 'PrivatBank: 5167 9856 6120 7654'
  },
  countdown: {
    days: 'дней',
    hours: 'часов',
    minutes: 'минут',
    seconds: 'секунд',
    ps: '... и мы будем женаты!'
  },
  getintouch: {
    text_1: 'Если вы хотите принять участие<br> в свадебной программе, вы можете<br> связаться с Демьяном',
    text_2: 'хочу принять участие'
  },
  messanger: {
    text_1: 'Для быстрого обмена<br> информацией, фото и видео<br> между нашими гостями<br> мы создали группу в VIBER',
    text_2: 'добавиться'
  },
  no_invitation: {
    text_1: 'чтобы открыть приглашение<br> воспользуйтесь своей уникальной<br> ссылкой =)'
  },
  colors: {
    text_1: 'Мы желаем дать совет,<br> Нарядится в свадьбы цвет,<br> В розовый, зелёный, золотой,<br> Выбери себе из них любой.<br> Ну а если цвет для вас другой родней,<br> Не волнуйтесь, уверяем, — Всё окей!'
  },
  photos: {
    text_1: 'здесь будут наши<br> свадебные фото и видео'
  }
}
