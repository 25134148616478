import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWow from 'vue-wow'
import VueI18n from 'vue-i18n'
import vClickOutside from 'v-click-outside'
import messages from '@/i18n/index.js'

import VAnimate from '@/components/global/VAnimate'
import VDropdown from '@/components/global/VDropdown'

Vue.use(VueWow)
Vue.use(VueI18n)
Vue.use(vClickOutside)
Vue.component('VAnimate', VAnimate)
Vue.component('VDropdown', VDropdown)

const i18n = new VueI18n({
  locale: 'en',
  messages
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
